// File equivalent to node.js but for browser
// WARNING: Redundancy - keep variables up-to-date in both files
export const LANGUAGES = {
  DEFAULT: "en",
  STORYBLOK_DEFAULT: "default",
  CHINESE: "zh-hans",
  STORYBLOK_CHINESE: "zh",
  GERMAN: "de",
  SPANISH: "es",
  FRENCH: "fr",
  PORTUGUESE: "pt",
}

export const ROUTES = {
  HOME_PRESLASH: "/",
  HOME_PRESLASHLESS: "/",
  PRODUCTS_PRESLASH: "/products",
  PRODUCTS_PRESLASHLESS: "products",
  EDITOR_PRESLASH: "/editor",
  EDITOR_PRESLASHLESS: "editor",
  BLOG_PRESLASH: "/blog",
  BLOG_PRESLASHLESS: "blog",
  NOT_FOUND_PRESLASH: "/404",
  NOT_FOUND_PRESLASHLESS: "404",
}

export const STORYBLOK_HOME_SLUG = "home"

export const DEFAULT_BLOG_POSTS_PER_PAGE = 12

export const CONTENT_TYPES = {
  PAGE: "page",
  PRODUCT: "product",
  BLOG_POST: "blog_post",
  BLOG: "blog",
  LAYOUT: "Layout",
}

export const LANGUAGES_ARRAY = [`en`, `de`, `es`, `zh-hans`, `fr`, `pt`]

export const STORYBLOK_LANGUAGES_ARRAY = [
  `default`,
  `de`,
  `es`,
  `zh`,
  `fr`,
  `pt`,
]

export const CHINESE_ISO_639_1 = "zh-Hans"

export const SCREENS = {
  PHONE: "576px",
}

export const COLORS = {
  MH_BLUE: "#0D2440",
  MH_ORANGE: "#E3AA30",
}

export const TEXT_VARIANTS = {
  WHITE: "white",
  BLACK: "black",
  GREY100: "#627893",
  DARK: "dark",
  LIGHT: "light"
}

export const BUTTON_VARIANTS = {
  PRIMARY: "primary",
  GHOST: "ghost",
  LINK: "link",
  ICON: "icon",
  BLUE: "dark_blue"
}

export const COLOR_VARIANTS = {
  LIGHT: "light",
  DARK: "dark",
  LIGHT_GRAY: "light-gray"
}

export const BUTTON_SIZE = {
  LARGE: "large",
  SMALL: "small",
}

// Adopted from SCSS variables
const SPACING = 5
const STD = 3 * SPACING

export const DEFAULT_PADDING_VALUES = {
  PADDING_TOP: 2 * STD,
  PADDING_BOTTOM: 2 * STD,
  PADDING_TOP_MOBILE: STD,
  PADDING_BOTTOM_MOBILE: STD,
}

export const POSITION = {
  LEFT: "left",
  RIGHT: "right"
}
