import React, { useState } from "react"
import { createPortal } from "react-dom"
import { useIntl } from "gatsby-plugin-intl"
import { Link as GatsbyLink } from "gatsby"
import isWebLink from "helpers/isWebLink"
import { isInternalLink } from "./helpers"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import cns from "classnames"
import Backdrop from "components/_common/Backdrop"
import s from "./link.module.scss"
import { LANGUAGES } from "consts"

export const TYPES = {
  WEB_LINK: "web-link",
  PIPEDRIVE_POP_UP: "pipedrive-pop-up",
}

export default function Link({
  to,
  children,
  className,
  activeClassName,
  newTab = false,
  type = "web-link",
  ...rest
}) {
  const intl = useIntl()
  const [pipedriveVisible, setPipedriveVisible] = useState(false)

  if (typeof to !== "string") {
    return null
  }

  function handlePipedriveClick(event) {
    event.preventDefault()
    setPipedriveVisible(true)
  }

  function handlePipedriveBackdropClose() {
    setPipedriveVisible(false)
  }

  if (typeof window === 'undefined' || !window.document) {
    return null;
  }

  if (type === TYPES.PIPEDRIVE_POP_UP) {
    return (
      <>
        {createPortal(
          <Backdrop
            open={pipedriveVisible}
            onBackdropClose={handlePipedriveBackdropClose}
          >
            <div
              className={cns("pipedriveWebForms", s.pipedriveWrapper)}
              data-pd-webforms={to}
            >
              <IconButton
                aria-label="close"
                className={s.backdropCloseButton}
                onClick={handlePipedriveBackdropClose}
              >
                <Icon>close</Icon>
              </IconButton>
              <iframe
                data-cookieconsent="statistics"
                data-cookieblock-src={`${to}?embeded=1`}
                title={children}
                className={cns(
                  s.pipedriveIframe,
                  "cookieconsent-optin-statistics"
                )}
              />
              <div
                className={cns(
                  s.pipedriveConsent,
                  "cookieconsent-optout-statistics"
                )}
              >
                <p className={s.pipedriveConsentText}>
                  {intl.formatMessage({
                    id:
                      "Please accept statistics cookies to view this content.",
                  })}
                </p>
              </div>
            </div>
          </Backdrop>,
          document.body
        )}
        <a
          className={cns(className, s.cta)}
          onClick={handlePipedriveClick}
          {...rest}
        >
          {children}
        </a>
      </>
    )
  }

  if (isInternalLink(to) || !isWebLink(to)) {
    let internalLink = to

    if (!internalLink.startsWith("/")) {
      internalLink = `/${to}`
    }

    if (internalLink.includes("home")) {
      internalLink = internalLink.replace("home", "")
    }

    if (internalLink.includes(`/${LANGUAGES.STORYBLOK_CHINESE}/`)) {
      internalLink = internalLink.replace(
        `/${LANGUAGES.STORYBLOK_CHINESE}/`,
        `/${LANGUAGES.CHINESE}/`
      )
    }

    if (internalLink.slice(-1) !== "/") {
      internalLink = `${internalLink}/`
    }

    return (
      <GatsbyLink
        activeClassName={activeClassName}
        className={className}
        to={internalLink}
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a
      className={className}
      href={to}
      target={newTab ? "_blank" : "_self"}
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </a>
  )
}
