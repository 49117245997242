import React from "react"
import cns from "classnames"
import Link from "components/_common/Link"
import isWebLink from "helpers/isWebLink"
import s from "./button.module.scss"
import { BUTTON_VARIANTS } from "consts"

/* Backwards compatibility */
const variants = {
  primary: BUTTON_VARIANTS.PRIMARY,
  ghost: BUTTON_VARIANTS.GHOST,
  white: BUTTON_VARIANTS.PRIMARY,
  dark: BUTTON_VARIANTS.GHOST,
  link: BUTTON_VARIANTS.LINK,
  icon: BUTTON_VARIANTS.ICON
}

export default function Button({
  onClick,
  text,
  link,
  type,
  size = "large",
  variant = "primary",
  color = "dark",
  mobileFullWidth = false,
  withIcon = false,
  className,
  mainColor
}) {
  const buttonClasses = cns({
    [s.wrapper]: true,
    [s[size]]: typeof size === "string",
    [s[variants[variant]]]: typeof variant === "string",
    [s[color]]: typeof color === "string",
    [s.mobileFullWidth]: mobileFullWidth,
    [className]: typeof className === "string",
    [s.withIcon]: variant === BUTTON_VARIANTS.ICON || withIcon,
    [s.blue]: mainColor === BUTTON_VARIANTS.BLUE
  })

  const internalLink = typeof link === "string" && !isWebLink(link)

  if (!text && variant !== BUTTON_VARIANTS.ICON) return null

  if (typeof link === "string" && internalLink) {
    return (
      <Link to={link} className={buttonClasses}>
        {text}
      </Link>
    )
  }

  if (typeof onClick === "function") {
    return (
      <button onClick={e => onClick(e)} className={buttonClasses}>
        {text}
      </button>
    )
  }

  if (type === "submit") {
    return (
      <button type="submit" className={buttonClasses}>
        {text}
      </button>
    )
  }

  return (
    <a
      href={link}
      rel="noreferrer noopener"
      target="_blank"
      className={buttonClasses}
    >
      {text}
    </a>
  )
}
